









































































































































































































import { Component, Vue } from "vue-property-decorator";
import{
  updateUserPwd,
  getEmailCode,
  verifyEmail,
  getUserInfo,
  getPhoneCode,
  validateNewMobile,
  validateOldMobile
}  from "@/api";
import { Form } from 'element-ui'

@Component
export default class Account extends Vue {
  dialogPasswordVisible = false;
  dialogMobileVisible = false;
  dialogEmailVisible = false;

  changePwd = {
    oldPwd: "",
    newPwd: "",
    comfirmPwd: "",
  };
  bindEmail = {
    email : "",
    verifyCode:""
  }
  bindMobile = {
    oldMobile : "",
    oldVerifyCode : "",
    newMobile : "",
    newVerifyCode : ""
  }
  //邮箱
  bindEmailButtonDisabled = true;
  emailButtonDisable = false;
  emailSecond = 0;
  //老手机
  unbindOldMobileButtonDisabled = true;
  oldMobileButtonDisable = false;
  oldMobileSecond = 0;
  //新手机
  bindNewMobileButtonDisabled = true;
  newMobileButtonDisable = false;
  newMobileSecond = 0;
  userInfo = {
    isVip : 0,
    companyId : "0",
    userId : 0,
    email:"",
    
  };
  bindflag = false;
  mobileStep = 0 ;
 emailRules = {
    email: 
    [  
      { required: true, message: '邮箱不能为空', trigger: 'blur' },
      { validator:this.validateEmail , trigger: "blur" },
    ],
    verifyCode:
    [  
      { required: true, message: '验证码不能为空', trigger: 'blur' },
    ]
  }
  oldMobileRules = {
    oldMobile: 
    [  
      { required: true, message: '旧手机不能为空', trigger: 'blur' },
      { validator:this.validatePhone , trigger: "blur" },
    ],
    oldVerifyCode:
    [  
      { required: true, message: '验证码不能为空', trigger: 'blur' },
    ]
  }
  newMobileRules = {
    newMobile: 
    [  
      { required: true, message: '新手机不能为空', trigger: 'blur' },
      { validator:this.validatePhone , trigger: "blur" },
    ],
    newVerifyCode:
    [  
      { required: true, message: '验证码不能为空', trigger: 'blur' },
    ]
  }
  //邮箱验证弹出层
  openEmialDialog(){
    this.bindEmail.verifyCode = "";
    this.emailSecond = 0;
    this.bindEmailButtonDisabled = true;
    if(!this.bindflag){
      this.bindEmail.email = "";
    }
    this.dialogEmailVisible = true;
  }
  //修改密码
  updateUserPwd(): void {
    let data = {
      newPassword: this.changePwd.newPwd,
      rePassword: this.changePwd.comfirmPwd,
      
    };
    
    updateUserPwd(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res);
            this.$message.success("修改成功");
            this.dialogPasswordVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
    }
  //验证旧手机
  validateOldMobile(): void {
    let data = {
      verifyCode: this.bindMobile.oldVerifyCode,
      
    };
    
    validateOldMobile(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res);
            this.mobileStep = 1;
            
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
   }
  //验证新手机
  validateNewMobile(): void {
    let data = {
      verifyCode: this.bindMobile.newVerifyCode,
      newMobile: this.bindMobile.newMobile,
      
    };
    
    validateNewMobile(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            
            this.$message.success("换绑成功");
            this.dialogMobileVisible = false;
            this.mobileStep = 0;
            this.bindMobile.oldMobile = this.bindMobile.newMobile ;
            this.bindMobile.oldVerifyCode = "";
            this.bindMobile.newMobile = "";
            this.bindMobile.newVerifyCode = "";

          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  mounted(): void {
    // this.fetchOrderList();
    this.getUserInfo();
    // this.listUserAddress();
  }
    //邮箱验证
  verifyEmail(isBind:string): void {
    let data = {
      email: this.bindEmail.email,
      verifyCode: this.bindEmail.verifyCode,
      isBind:isBind
    };
    
    verifyEmail(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res);
            this.$message.success('Y'== isBind ?"绑定成功":"解绑成功");
            this.dialogEmailVisible = false;
            this.getUserInfo();
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //获取验证码
  getEmailCode(email : string): void {
      const regEmail =  /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (email == "" || email == undefined || email == null) {
          this.$message.error("邮箱不能为空");
          return;
      }
      if (email!= '' && !regEmail.test(email)) {
          this.$message.error("邮箱格式不正确");
          return;
      }
      getEmailCode(this.bindEmail.email)
        .then((res) => {
          // console.log(res);
          this.bindEmailButtonDisabled = false;
          this.emailSecond = 60;
          this.emailButtonDisable = true;
          var smsTimer = setInterval(() => {
            this.emailSecond = this.emailSecond - 1;
            if (this.emailSecond <= 0) {
              this.emailButtonDisable = false;
              clearInterval(smsTimer);
            }
          }, 1000);
        })
        .catch(() => {
          console.log("error test");
        });
  }
  getOldPhoneCode(): void {
    const reg = /^[1][3-9][0-9]{9}$/;
     if (this.bindMobile.oldMobile == "" || this.bindMobile.oldMobile == undefined || this.bindMobile.oldMobile == null) {
          this.$message.error("手机不能为空");
          return;
    } else {
      if (!reg.test(this.bindMobile.oldMobile) && this.bindMobile.oldMobile != "") {
         this.$message.error("请输入正确的电话号码");
          return;
      }
    }
    
      getPhoneCode(this.bindMobile.oldMobile)
        .then((res) => {
          // console.log(res);
          this.unbindOldMobileButtonDisabled = false;
          this.oldMobileSecond = 60;
          this.oldMobileButtonDisable = true;
          var smsTimer = setInterval(() => {
            this.oldMobileSecond = this.oldMobileSecond - 1;
            if (this.oldMobileSecond <= 0) {
              this.oldMobileButtonDisable = false;
              clearInterval(smsTimer);
            }
          }, 1000);
        })
        .catch(() => {
          console.log("error test");
        });
  }
  getNewPhoneCode(): void {
    const reg = /^[1][3-9][0-9]{9}$/;
     if (this.bindMobile.newMobile == "" || this.bindMobile.newMobile == undefined || this.bindMobile.newMobile == null) {
          this.$message.error("手机不能为空");
          return;
    } else {
      if (!reg.test(this.bindMobile.newMobile) && this.bindMobile.newMobile != "") {
         this.$message.error("请输入正确的电话号码");
          return;
      }
    }
    
      getPhoneCode(this.bindMobile.newMobile)
        .then((res) => {
          // console.log(res);
          this.bindNewMobileButtonDisabled = false;
          this.newMobileSecond = 60;
          this.newMobileButtonDisable = true;
          var smsTimer = setInterval(() => {
            this.newMobileSecond = this.newMobileSecond - 1;
            if (this.newMobileSecond <= 0) {
              this.newMobileButtonDisable = false;
              clearInterval(smsTimer);
            }
          }, 1000);
        })
        .catch(() => {
          console.log("error test");
        });
  }
  getUserInfo(): void{
    getUserInfo({}).then(res => {
      if(res.status == 200){
          if(res.data.code == 200){
            this.userInfo = res.data.user
            window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            this.bindMobile.oldMobile = res.data.user.phonenumber
            this.bindEmail.email = this.userInfo.email;
            if(this.userInfo.email == null || this.userInfo.email == ""){
              this.bindflag = false;
            }else{
              this.bindflag = true;
            }
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
};
   validateBindEmailForm(): void {
    (this.$refs['bindEmailForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()

        this.verifyEmail(this.bindflag?"N":"Y");
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  validateOldMobileForm(): void {
    (this.$refs['oldMobileForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()

        this.validateOldMobile();
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  validateNewMobileForm(): void {
    (this.$refs['newMobileForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()

        this.validateNewMobile();
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
   validateEmail(rule: any, value: string, callback: any) {
    const reg =  /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
    if (value == "" || value == undefined || value == null) {
      callback();
    } else {
      if (!reg.test(value) && value != "") {
        callback(new Error("请输入正确的邮箱"));
      } else {
        callback();
      }
    }
  }
  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (value == "" || value == undefined || value == null) {
      callback();
    } else {
      if (!reg.test(value) && value != "") {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    }
  }
}
